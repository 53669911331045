export const COMMENT_LIST = Object.freeze([
  {
    id: 2,
    postId: 1,
    userProfile: 'string',
    userDisplay: '송이1',
    isWriter: true,
    content: '댓글 내용내용냐옹냐옹',
    liked: false, // 백엔드에서 추가 필요
    likeCount: 12,
    reportCount: 0,
    createdAt: '2024-07-09T04:13:06.215Z',
    updatedAt: '2024-07-14T04:13:06.215Z',
    deletedAt: '2024-07-14T04:13:06.215Z',
    isVisible: true,
    isUpdated: true,
    isDeleted: false,
    parentId: 1,
    children: [
      {
        id: 3,
        postId: 1,
        userProfile: 'string',
        userDisplay: '송이2',
        isWriter: true,
        content:
          '댓글 두 번째다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 긴 대댓글이다옹 냐얀야냐',
        liked: false, // 백엔드에서 추가 필요
        likeCount: 4,
        reportCount: 0,
        createdAt: '2024-07-14T04:13:06.215Z',
        updatedAt: '2024-07-14T04:13:06.215Z',
        deletedAt: '2024-07-14T04:13:06.215Z',
        isVisible: true,
        isUpdated: false,
        isDeleted: false,
        parentId: 1,
        children: ['string'],
      },
      {
        id: 4,
        postId: 1,
        userProfile: 'string',
        userDisplay: '송이3',
        isWriter: true,
        content: '삼댓냥냥냥',
        liked: true, // 백엔드에서 추가 필요
        likeCount: 2,
        reportCount: 0,
        createdAt: '2024-07-14T04:13:06.215Z',
        updatedAt: '2024-07-14T04:13:06.215Z',
        deletedAt: '2024-07-14T04:13:06.215Z',
        isVisible: true,
        isUpdated: false,
        isDeleted: false,
        parentId: 1,
        children: ['string'],
      },
    ],
  },
  {
    id: 5,
    postId: 1,
    userProfile: 'string',
    userDisplay: '송이2',
    isWriter: true,
    content: '댓글 두 번째다옹',
    liked: true, // 백엔드에서 추가 필요
    likeCount: 4,
    reportCount: 0,
    createdAt: '2024-07-14T04:13:06.215Z',
    updatedAt: '2024-07-14T04:13:06.215Z',
    deletedAt: '2024-07-14T04:13:06.215Z',
    isVisible: true,
    isUpdated: false,
    isDeleted: false,
    parentId: 1,
    children: [],
  },
  {
    id: 6,
    postId: 1,
    userProfile: 'string',
    userDisplay: '송이3',
    isWriter: true,
    content: '삼댓냥냥냥',
    liked: false, // 백엔드에서 추가 필요
    likeCount: 2,
    reportCount: 0,
    createdAt: '2024-07-14T04:13:06.215Z',
    updatedAt: '2024-07-14T04:13:06.215Z',
    deletedAt: '2024-07-14T04:13:06.215Z',
    isVisible: true,
    isUpdated: false,
    isDeleted: false,
    parentId: 1,
    children: [],
  },
]);

export const POST_CATEGORIES = Object.freeze([
  {
    id: 'tip',
    label: '꿀팁',
  },
  {
    id: 'life',
    label: '일상',
  },
  {
    id: 'my-major',
    label: '우리과 찾기',
  },
]);

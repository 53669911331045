export const POST_CONTENT = Object.freeze({
  postId: 7,
  userId: 10,
  userDisplay: '송이1',
  category: 'testCategory',
  title: '오이소박이',
  content:
    '내가 먹고싶다고하니까 엄마가 오이 1박스를 시켜서ㅋㅋㅋㅋ본가 온 김에 엄마랑 이 시간까지 김장했으~ 오이 23개를 잘 씻어준다 1박스는 20개였는데 집에있던 3개 추가 오이를 4등분 한 후에 칼집을 내준다 이거 혼자 다함 대박 힘들어 양념소를 만든다 고춧가루 새우젓 생강 다진마늘 양파 당근 부추 등등 사실 기억 다 못함 김장 생각보다 빨리 끝남 완성! 너무너무너무 맛있음... 근데 손에 오이물들어서... 잘 안빠지는 중...^^',
  viewCount: 10,
  likeCount: 0,
  scrapCount: 2,
  reportCount: 0,
  createdAt: '2024-07-01T12:06:22.289Z',
  updatedAt: '2024-07-13T12:06:22.289Z',
  visible: true,
  liked: true, // 백엔드에서 추가 필요
  edited: true,
  notice: true,
});
